<template>
  <v-progress-circular :size="50" color="primary" indeterminate />
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
